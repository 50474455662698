<template>
  <v-app id="app">
    <v-main>
      <Navbar v-if="!configStore.isMaintenanceEnabled && !authStore.isBanned" />
      <router-view
        v-if="!configStore.isMaintenanceEnabled && !authStore.isBanned"
      />
      <Banned v-else-if="authStore.isBanned" />
      <Maintenance v-else />
    </v-main>
    <Footer />
  </v-app>
</template>

<script setup>
import Navbar from "./components/Navbar.vue";
import Maintenance from "@/components/Maintenance.vue";
import Banned from "@/components/Banned.vue";
import Footer from "@/components/Footer.vue";
import { useConfigStore } from "@/stores/configStore";
import { useAuthStore } from "@/stores/authStore";

const configStore = useConfigStore();
const authStore = useAuthStore();

configStore.initializeConfigListener();
authStore.initializeUserListener();
</script>

<style>
#app {
  background: url("./assets/bg.png") no-repeat center center fixed;
}
</style>
