// Composables
import { socket } from "./socket.js";
import { createApp } from "vue";
import { createPinia } from "pinia";
import ToastPlugin from "vue-toast-notification";

//Toast Css
import "vue-toast-notification/dist/theme-bootstrap.css";
// Plugins
import { registerPlugins } from "@/plugins";
// Components
import App from "./App.vue";

const app = createApp(App);
const pinia = createPinia();

// Add a global toast property to Pinia
pinia.use(({ store }) => {
  store.$toast = app.config.globalProperties.$toast; // Toast in store
});

registerPlugins(app);
app.use(pinia);
app.use(ToastPlugin, {
  position: "bottom",
  duration: 5000,
});
app.mount("#app");
