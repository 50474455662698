<template>
  <v-app-bar :elevation="11" color="#1a1c26">
    <v-app-bar-title></v-app-bar-title>

    <div v-if="user.username" class="user-bal d-flex flex-row align-center">
      <div class="bal pr-2 text-center align-center justify-center">
        <v-icon>mdi-currency-usd</v-icon>{{ getBalance }}
      </div>
      <v-btn color="success" variant="tonal" rounded prepend-icon="mdi-wallet">
        Wallet
      </v-btn>
    </div>

    <template #append>
      <v-menu v-if="user && user.username">
        <template #activator="{ props }">
          <v-btn variant="text" v-bind="props">
            <v-avatar :image="user.avatar" class="me-2" />
            {{ user.username }}
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.route"
            @click="$router.push(item.route)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-if="!user.username"
        color="success"
        @click="authStore.loginWithSteam"
      >
        <v-icon icon="mdi-steam" />
        Login with Steam
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script setup>
import { useAuthStore } from "@/stores/authStore";
import { onMounted, computed } from "vue";

const authStore = useAuthStore();

//Computed
const user = computed(() => authStore.user);
const getBalance = computed(() => authStore.user?.balance);

const items = [
  { title: "Profile", route: "/account/profile" },
  { title: "Security", route: "/account/security" },
];

onMounted(() => {
  authStore.initializeUserListener();
});
</script>

<style>
.user-bal {
  font-size: 1.2rem;
  font-weight: 500;
  background-color: #2d2d2d;
  border-radius: 25px;
  border: 1px solid #222;
}
</style>
