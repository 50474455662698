import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { socket } from "../socket";

export const useConfigStore = defineStore("config", {
  state: () => ({
    maintenance: {
      enabled: true,
      message:
        "The site is currently under maintenance. Please check back later.",
    },
    whitelist: {
      users: [],
      default: [],
    },
    socials: {
      discord: "",
      twitter: "",
      facebook: "",
      instagram: "",
    },
  }),
  getters: {
    isMaintenanceEnabled() {
      return this.maintenance.enabled;
    },
    getMaintenanceMessage() {
      return this.maintenance.message;
    },
    getWhitelistUsers() {
      return this.whitelist.users;
    },
    getSocials() {
      return this.socials;
    },
    isAdmin() {
      const authStore = useAuthStore();
      return this.whitelist.users.includes(authStore.user.steamId);
    },
  },
  actions: {
    initializeConfigListener() {
      socket.on("config", (configData) => {
        this.maintenance = configData.maintenance;
        this.whitelist = configData.whitelist || { users: [], default: [] };
        this.socials = configData.socials;

        // Automatically bypass maintenance if the user is an admin
        if (this.isAdmin) {
          this.maintenance.enabled = false;
        }
      });
    },
  },
});
