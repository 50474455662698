<template>
  <div class="Maintenance">
    <img src="../assets/logo.png" alt="Site Logo" class="logo" />
    <div class="MaintenanceMessage">
      <h2>{{ maintenanceMessage }}</h2>
      <div class="socials">
        <div v-if="socials.discord">
          <v-icon>mdi-discord</v-icon>
        </div>
        <div v-if="socials.twitter">
          <v-icon>mdi-twitter</v-icon>
        </div>
        <div v-if="socials.facebook">
          <v-icon>mdi-facebook</v-icon>
        </div>
        <div v-if="socials.instagram">
          <v-icon>mdi-instagram</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useConfigStore } from "@/stores/configStore";

const configStore = useConfigStore();

const maintenanceMessage = computed(() => configStore.getMaintenanceMessage);
const socials = computed(() => configStore.getSocials);

onMounted(() => {
  if (configStore.isAdmin) {
    configStore.maintenance.enabled = false;
  }
});
</script>

<style scoped>
.Maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

.logo {
  width: 300px;
  height: 300px;
}

.MaintenanceMessage {
  color: #fff;
  font-size: large;
  font-weight: 700;
  text-align: center;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center; /* Corrected typo here */
  padding: 20px 10px 0 0;
}

.socials :hover {
  cursor: pointer;
  color: #5c9fef;
}
</style>
