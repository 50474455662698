<template>
  <div v-if="authStore.isBanned" class="bannedContainer">
    <img src="../assets/BannedLogo.png" alt="Banned Logo" class="logo" />
    <div class="bannedMessage">Reason: {{ bannedMessage }}</div>
    <div class="bannedMessage">You are banned till {{ convertDate() }}</div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useToast } from "vue-toast-notification";

const authStore = useAuthStore();
const toast = useToast({
  position: "bottom",
  duration: 5000,
});
const bannedMessage = computed(() => authStore.user?.ban.reason);
const expirationDate = computed(() => authStore.user?.ban.expires);

function convertDate() {
  if (!expirationDate.value) return "Unknown date";

  const date = new Date(expirationDate.value);

  // Format the date without timezone
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  // Get timezone separately
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Return the formatted date with timezone in parentheses
  return `${formattedDate} (${timeZone})`;
}

onMounted(() => {
  if (authStore.isBanned) {
    toast.error("You are banned");
  }
});
</script>

<style scoped>
.bannedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

.logo {
  width: 300px;
  height: 300px;
}

.bannedMessage {
  color: #fff;
  font-size: large;
  font-weight: 700;
  text-align: center;
}
</style>
