import { reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
  connected: false,
});

// "undefined" means the URL will be computed from the `window.location` object
const URL =
  window.location.hostname === "localhost"
    ? "http://localhost:4279"
    : "https://staging.noobs.dev";

export const socket = io(URL, {
  transports: ["websocket"],
});

socket.on("connect", () => {
  state.connected = true;
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.on("connect_error", (err) => {
  console.error("WebSocket connection error:", err);
});

socket.on("auth_error", (data) => {
  console.warn("Authentication error:", data.message);
});
