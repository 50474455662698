import { defineStore } from "pinia";
import { socket } from "../socket";

const isLocalHost =
  window.location.hostname === "localhost"
    ? "http://localhost:4279"
    : "https://staging.noobs.dev";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: {
      username: "",
      steamId: "",
      avatar: "",
      tradeUrl: "",
      account_creation: null,
      communityvisibilitystate: 0,
      balance: 0.0,
      intercom: null,
      email: {
        address: "",
        verified: false,
      },
      ban: {
        banned: false,
        reason: null,
        expires: null,
      },
      staffLevel: 0,
      twoFactor: {
        enabled: false,
        qrCode: "",
        secret: "",
        revocationCode: "",
      },
      pastTrades: [],
    },
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
    isTwoFactorEnabled(state) {
      return state.user.twoFactor?.enabled || false;
    },
    isBanned(state) {
      return state.user.ban?.banned || false;
    },
    getPastTrades(state) {
      return state.user.pastTrades;
    },
    getBalance(state) {
      return state.user.balance;
    },
  },
  actions: {
    /**
     * Initializes the user listener for real-time updates.
     * Call this action when your application starts or when the user logs in.
     */
    initializeUserListener() {
      socket.on("user", (userData) => {
        this.user = userData;
      });
    },

    loginWithSteam() {
      // Check if local or production/staging
      if (window.location.hostname === "localhost") {
        window.location.href = "http://localhost:4279/auth/steam"; // Local
      } else {
        window.location.href = "https://staging.noobs.dev/auth/steam"; // Staging
      }
    },

    async saveTradeUrl(tradeUrl) {
      try {
        const response = await fetch(`${isLocalHost}/account/tradeurl`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ tradeUrl }),
        });

        const data = await response.json();

        if (!response.ok) {
          this.$toast.error(data.message || "Failed to save trade URL.");
          throw new Error(data.message || "Failed to save trade URL.");
        }

        this.$toast.success(data.message || "Trade URL saved successfully!");
        return data;
      } catch (error) {
        this.$toast.error(error.message || "Something went wrong.");
        throw error;
      }
    },

    async saveEmail(email) {
      try {
        const response = await fetch(`${isLocalHost}/account/email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ email }),
        });

        const data = await response.json();

        if (!response.ok) {
          this.$toast.error(data.message || "Failed to save email.");
          throw new Error(data.message || "Failed to save email.");
        }

        this.$toast.success(data.message || "Email saved successfully!");
        return data;
      } catch (error) {
        this.$toast.error(error.message || "Something went wrong.");
        throw error;
      }
    },

    logout() {
      this.user = null;
      document.cookie =
        "TurboTradesGG=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      // Optional: Disconnect socket or perform other cleanup
      socket.disconnect();
    },

    async enableTwoFactor() {
      try {
        const response = await fetch(`${isLocalHost}/2fa/enable`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Include cookies/session
        });

        const data = await response.json();

        if (response.ok && data.success) {
          this.user.twoFactor = {
            enabled: true,
            secret: data.response.twoFactor.secret,
            revocationCode: data.response.twoFactor.revocationCode,
            qrCode: data.response.twoFactor.qrCode, // Include QR Code
          };

          this.$toast.success(
            "Two-factor authentication enabled successfully!",
          );
        } else {
          this.$toast.error(
            data.message || "Failed to enable two-factor authentication.",
          );
        }
      } catch (error) {
        console.error("Error in enableTwoFactor:", error);
        this.$toast.error("Something went wrong while enabling 2FA.");
      }
    },

    // 2FA: Disable Two-Factor Authentication
    async disableTwoFactor(revocationCode) {
      try {
        const response = await fetch(`${isLocalHost}/2fa/disable`, {
          method: "POST",
          credentials: "include", // Ensures cookies are sent with the request
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ revocationCode }), // Pass the revocation code in the request body
        });

        const data = await response.json();

        if (response.ok && data.success) {
          this.user.twoFactor = {
            enabled: false,
            qrCode: "",
            secret: "",
            revocationCode: "",
          };
          this.$toast.success(
            "Two-factor authentication disabled successfully!",
          );
        } else {
          this.$toast.error(
            data.message || "Failed to disable two-factor authentication.",
          );
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("Something went wrong while disabling 2FA.");
        throw error;
      }
    },
    async verifyTwoFactor(userCode) {
      try {
        const response = await fetch(`${isLocalHost}/2fa/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Ensure cookies are sent
          body: JSON.stringify({ userCode }),
        });

        const data = await response.json();

        if (response.ok && data.success) {
          return true; // Verification successful
        } else {
          console.error(data.message || "Invalid verification code.");
          return false; // Verification failed
        }
      } catch (error) {
        console.error("Error verifying 2FA:", error);
        return false; // Handle network or other errors
      }
    },
    async getUserBalance() {
      try {
        const response = await fetch(`${isLocalHost}/account/balance`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        const data = await response.json();

        if (!response.ok) {
          this.$toast.error(data.message || "Failed to get balance.");
          throw new Error(data.message || "Failed to get balance.");
        }

        return data.balance.toFixed(2);
      } catch (error) {
        this.$toast.error(error.message || "Something went wrong.");
        throw error;
      }
    },
  },
});
