export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/workspace/TurboTradesGG/Frontend/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/account',
    /* internal name: '/account' */
    /* no component */
    children: [
      {
        path: 'profile',
        name: '/account/profile',
        component: () => import('/workspace/TurboTradesGG/Frontend/src/pages/account/profile.vue'),
        /* no children */
      },
      {
        path: 'security',
        name: '/account/security',
        component: () => import('/workspace/TurboTradesGG/Frontend/src/pages/account/security.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/sell',
    name: '/sell',
    component: () => import('/workspace/TurboTradesGG/Frontend/src/pages/sell.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

